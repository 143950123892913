import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Slider } from 'antd';
import styles from 'components/Fields/header.module.css';
import './SliderField.css';

interface Props {
  name: string;
  fieldQuestion: string;
}

const SliderField: FC<Props> = ({ name, fieldQuestion }) => {
  /*
    This will import various methods from React Hook Form to manage
    form state and control validation rules.
    This has a type of any as the ActivityName for each slider can be different
    according to what is gotten from the API call for it.
  */
  const { control } = useFormContext<any>();

  // Generate marks for slider from 1 to 10
  const marks: { [key: number]: string } = {};
  for (let markNumber = 1; markNumber <= 10; markNumber++) {
    marks[markNumber] = markNumber.toString();
  }

  return (
    <>
      {/* Renders the Question Title and Question */}
      <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        control={control}
        defaultValue={1} //this slider has a default value of 1
        render={({ field }) => (
          <>
            <Slider
              data-testid={`${name}-Slider`}
              {...field}
              min={1}
              max={10}
              step={1}
              marks={marks}
            />
          </>
        )}
      />
    </>
  );
};

export default SliderField;
