import { useQuery } from 'react-query';
import {
  getAllActivities,
  getAllMotivation,
  getAllDiscoveries,
} from 'http/ApiCalls';
import { transformFormOptions } from 'utils/formUtils';

/*
This function is used to wrap three API calls in one promise.
OnSuccess it will then attempt to map the returned values for proper use and structure
for displaying options in the form for various combo boxes
*/

const fetchData = async () => {
  //wrapping all functions in promise.all to manage as a whole
  const [activitiesData, discoveriesData, motivationData] =
    await Promise.all([
      getAllActivities(),
      getAllDiscoveries(),
      getAllMotivation(),
    ]);

  //mapping each return value from Id, name to label and value for input into components
  const activitiesOptions = transformFormOptions(activitiesData);
  const discoveriesOptions = transformFormOptions(discoveriesData);
  const motivationOptions = transformFormOptions(motivationData);

  return {
    activitiesOptions,
    discoveriesOptions,
    motivationOptions,
  };
};

/*
This hook will return useQuery functionality for the fetching of data from three
endpoints. it will return all the options retrieved as well as isLoading or isSuccess states.
*/
const useFetchData = () => {
  /*
  Getting all functions required as well as an isLoading/isSuccess
  which will be used to achieve visual effects in form
  */
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: 'fetchAllData',
    queryFn: fetchData,
    staleTime: Infinity, //this will stop constant refeteches during changes of the form
  });

  //destructuring in order to get specific options that will be given to specific from fields
  const { activitiesOptions, discoveriesOptions, motivationOptions } =
    data || {};

  /*
  this will manually trigger a refetch when a user wants to try
  reconnecting to the form in case of network error
  */
  const handleReloadData = () => {
    refetch();
  };

  //all these are returned to use in the form
  return {
    isLoadingData: isLoading,
    isError,
    handleReloadData,
    activitiesOptions,
    discoveriesOptions,
    motivationOptions,
  };
};

export default useFetchData;
