import { Button } from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  MailOutlined,
  PhoneFilled,
  HomeFilled,
} from '@ant-design/icons';
import styles from './companyheader.module.css';
import TikTokOutlined from 'components/SVGComponents/TikTokOutlined';
import TripAdvisorOutlined from 'components/SVGComponents/TripAdvisorOutlined';

/*
This file returns a list of Icons with links to various
Share Village related content
*/

const CompanyHeader = () => {
  return (
    <div className={styles.companyHeader}>
      {/*Loads FacebookOutlined component which links to Share Discovery Villages Facebook page*/}
      <a
        href="https://www.facebook.com/sharevillage/"
        aria-label=" Go to Share Discovery Village Facebook Page"
      >
        <Button
          className={styles.socialMediaBTN}
          icon={<FacebookOutlined style={{ fontSize: '24px' }} />}
        ></Button>
      </a>
      {/*Loads TwitterOutlined component which links to Share Discovery Villages Twitter page*/}
      <a
        href="https://twitter.com/sharevillage?lang=en"
        aria-label=" Go to Share Discovery Village Twitter Page"
      >
        <Button
          className={styles.socialMediaBTN}
          icon={<TwitterOutlined style={{ fontSize: '24px' }} />}
        ></Button>
      </a>
      {/*Loads YoutubeOutlined component which links to Share Discovery Villages YouTube page*/}
      <a
        href="https://www.youtube.com/channel/UCkbugmP9QEQbz9ENjIlWm_A"
        aria-label=" Go to Share Discovery Village Youtube Channel"
      >
        <Button
          className={styles.socialMediaBTN}
          icon={<YoutubeOutlined style={{ fontSize: '24px' }} />}
        ></Button>
      </a>
      {/*Loads InstagramOutlined component which links to Share Discovery Villages Instagram page*/}
      <a
        href="https://www.instagram.com/sharevillage/"
        aria-label=" Go to Share Discovery Village Instagram Page"
      >
        <Button
          className={styles.socialMediaBTN}
          icon={<InstagramOutlined style={{ fontSize: '24px' }} />}
        ></Button>
      </a>
      {/*Loads TikTokOutlined component which links to Share Discovery Villages TikTok page*/}
      <a
        href="https://www.tiktok.com/@sharevillage "
        aria-label=" Go to Share Discovery Village Tiktok Page"
      >
        <Button
          className={`${styles.socialMediaBTN} tikTokLinkBtn`}
          icon={<TikTokOutlined />}
        ></Button>
      </a>
      {/*Loads TripAdvisorOutlined component which links to Share Discovery Villages TripAdvisor page*/}
      <a
        href="https://www.tripadvisor.co.uk/Hotel_Review-g1457552-d1456028-Reviews-Share_Discovery_Village-Lisnaskea_County_Fermanagh_Northern_Ireland.html"
        aria-label=" Go to Share Discovery Village TripAdvisor Page"
      >
        <Button
          className={`${styles.socialMediaBTN} tripAdvisorLinkBtn`}
          icon={<TripAdvisorOutlined />}
        ></Button>
      </a>
      {/*Loads phone symbol and phone number for Share Discovery Village */}
      <div className={styles.contactInfo}>
        <PhoneFilled style={{ fontSize: '24px' }}></PhoneFilled>
        <span className={styles.contactInfoText}>
          +44 (0) 28 6772 2122
        </span>
      </div>
      {/*Loads email symbol and email address for Share Discovery Village */}
      <div className={styles.contactInfo}>
        <MailOutlined style={{ fontSize: '24px' }}></MailOutlined>
        <span className={styles.contactInfoText}>
          info@sharevillage.org
        </span>
      </div>
      {/*Loads HomeOutlined component which links to Share Discovery Villages facebook page*/}
      <a href="https://www.sharevillage.org">
        <Button
          className={styles.homeBTN}
          icon={<HomeFilled style={{ fontSize: '24px' }} />}
          name={'shareVillageHomeSiteLinkBTN'}
        ></Button>
      </a>
    </div>
  );
};

export default CompanyHeader;
