import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'antd';
import styles from 'components/Fields/header.module.css';
import './SelectField.css';
import {
  fieldOptions,
  Inputs,
  validationRules,
} from 'components/Fields/componentTypes';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';

interface Props {
  name: keyof Inputs;
  fieldQuestion: string;
  options: fieldOptions[];
  placeHolder?: string;
  rules?: validationRules;
}

const SelectField: FC<Props> = ({
  name,
  fieldQuestion,
  options,
  placeHolder,
  rules,
}) => {
  const { control, formState } = useFormContext<Inputs>();
  const { errors } = formState;
  const selectError = errors[name]?.message?.toString() ?? '';

  return (
    <>
      <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field }) => (
          <div>
            <Select
              data-testid={`${name}-Select`}
              {...field}
              placeholder={
                <p className={styles.placeHolder}>
                  {placeHolder ?? 'Select an activity'}
                </p>
              }
              className={styles.selectBoxStyle}
              options={options}
              aria-label={placeHolder}
            />
          </div>
        )}
      />
      {/* Displays error message if validation rules passed in are invoked */}
      {errors[name] && <ErrorMessage errorMessage={selectError} />}
    </>
  );
};

export default SelectField;
