import React from 'react';
import { Select } from 'antd';
import { highContrastOptions } from 'constants/formOptions';
import { getColourTwo, getColourOne } from 'utils/formUtils';

//selectedHighContrast is passed in to be used as currently selected value
interface HighContrastSelectFieldProps {
  selectedHighContrast: string;
  onChange: (selectedHighContrast: string) => void;
}

const HighContrastSelectField: React.FC<
  HighContrastSelectFieldProps
> = ({ selectedHighContrast, onChange }) => {
  // Updates --selected-colour-one and --selected-colour-two with user selected values
  const handleChange = (selectedValue: string) => {
    // sets the variables colourTwo and colourOne with the selected value
    // using the getColourTwo and getColourOne methods in formUtils
    const colourTwo = getColourTwo(selectedValue);
    const colourOne = getColourOne(selectedValue);

    // Sets --selected-colour-one globally
    document.documentElement.style.setProperty(
      '--selected-colour-one',
      colourOne,
    );

    // Sets --selected-colour-two globally
    document.documentElement.style.setProperty(
      '--selected-colour-two',
      colourTwo,
    );

    // Updates the placeholder in the dropdown
    onChange(selectedValue);
  };
  /*
  This will render this component to manage form styling
  it will set the font size to the current selected option.
  */
  return (
    <Select
      value={selectedHighContrast}
      style={{
        left: '2%',
        width: '96%',
        height: '60px',
        paddingBottom: '15px',
        textAlign: 'center',
      }}
      onChange={handleChange}
      options={highContrastOptions}
      data-testid="highContrast"
    />
  );
};

export default HighContrastSelectField;
