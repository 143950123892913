import { apiReturnType } from 'http/ApiCalls/apiTypes';

//Get endpoint to fetch all motivation options
export const getAllMotivation = async (): Promise<
  apiReturnType[]
> => {
  /*
  This file is making a GET endpoint call to /motivation/getAllMotivation
  with the root URL defined in .env file.
  */
  const response = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/motivation/getAllMotivation`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    },
  );
  //returning response to be handled in hook
  return response.json();
};
