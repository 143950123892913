/*
Form fields for visitor information that will need to be collected
with this key and the value in this component.
This will be to send this to the /visitor/addVisitor API endpoint
*/

export const visitorDataKeys: string[] = [
  'groupType',
  'groupName',
  'email',
  'dateRange',
  'location',
  'ageRange',
  'firstVisit',
  'discoveryId',
  'motivationId',
  'similarExperience',
  'whichSimilarExperience',
  'shareHearElaborate',
];

/*
Form fields for visitor experience information that will need to be collected
with this key and the value in this component.
This will be to send this to the /experience/addExperience API endpoint
*/

export const experienceDataKeys: string[] = [
  'goodValue',
  'overallExperienceScore',
  'favouriteAspect',
  'improvements',
  'shareQuote',
  'favouriteActivityId',
  'leastFavouriteActivityId',
  'suggestedNewActivity',
  'statement',
];

/*
Form fields for activityName which will be then be used to collect the names
and rating each name has been given.
This will be to send this to the /activity/addActivityRating API endpoint
*/

export const activityRatingDataKey: string[] = ['activityName'];
