import React, { FC } from 'react';
import { Typography } from 'antd';

interface ErrorTextProps {
  errorMessage: string;
}

/*
Ant d Typography component to display error message when
field validation rules are not met.
*/
const ErrorMessage: FC<ErrorTextProps> = ({ errorMessage }) => (
  <Typography.Text
    type="danger"
    style={{
      marginTop: '5px',
      fontWeight: 'bold',
      fontFamily: "var(--selected-font, 'OpenSans'), sans-serif",
      fontSize: 'var(--selected-font-size, 15px)',
    }}
  >
    {errorMessage}
  </Typography.Text>
);

export default ErrorMessage;
