import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'antd';
import {
  fieldOptions,
  Inputs,
  validationRules,
} from 'components/Fields/componentTypes';
import styles from 'components/Fields/header.module.css';
import SliderField from 'components/Fields/SliderField/SliderField';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';

const { Option } = Select; // Destructure Option from Select

interface Props {
  name: keyof Inputs;
  fieldQuestion: string;
  options: fieldOptions[];
  rules: validationRules;
}

const ActivitySelector: React.FC<Props> = ({
  name,
  fieldQuestion,
  options,
  rules,
}) => {
  /*
    This will import various methods from React Hook Form to manage
    form state and control validation rules.
  */
  const { control, watch, formState } = useFormContext<Inputs>();

  /*
    This will import to see if there are any errors in the form state
    from which an error message is formatted which will be displayed if so.
  */
  const { errors } = formState;
  const textError = errors[name]?.message?.toString() ?? '';

  //watcher for selected activities to render slider rating for each/
  const selectedActivities = watch(name) || [];

  return (
    <div>
      {/* Renders the Question Title and Question */}
      <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            data-testid={`${name}-ActivitySelect`}
            mode="multiple" //multiple options can be selected
            style={{
              width: '100%',
              border: '1px solid black',
              borderRadius: '5px',
              height: 'fit-content',
            }}
            placeholder="Select activities"
            className={styles.selectBoxStyle}
          >
            {/*Displays various options and assigns a label to display and a value for each*/}
            {options.map((option) => (
              <Option key={option.value} value={option.label}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}
      />
      {/*
        This will for every selected activity render a slider field for a rating.
        This will allow a number rating to be assigned to each activity
        */}
      {selectedActivities.map((selectedActivity: string) => (
        <SliderField
          key={selectedActivity}
          name={`${selectedActivity}-rating`}
          data-testid={`rating-slider`}
          fieldQuestion={`${selectedActivity} Rating`}
        />
      ))}
      {/* Displays error message if validation rules passed in are invoked */}
      {errors[name] && <ErrorMessage errorMessage={textError} />}
    </div>
  );
};

export default ActivitySelector;
