import {
  Inputs,
  endpointData,
} from 'components/Fields/componentTypes';
import {
  activityReturn,
  apiReturnType,
} from 'http/ApiCalls/apiTypes';

//extract the values needed for each endpoint submission and their keys
export const extractPageData = (
  inputKeys: string[],
  formState: any,
) => {
  return Object.entries(formState)
    .filter(([key]) => inputKeys.includes(key))
    .reduce((endpointData: endpointData, [key, value]) => {
      endpointData[key] = value;
      return endpointData;
    }, {});
};

/*
This maps the API return values and restructures them so they can be displayed as options in form fields.
The id of the option returned from the API is mapped to value in the array.
The name of the option returned from the API is then mapped to label in the array.
*/
export const transformFormOptions = (
  dataArray: activityReturn[] | apiReturnType[],
) => {
  return dataArray.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

//this function extract the value out of a date range object and returns them.
export const transformDateRange = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => ({
  startDate,
  endDate,
});

//this function updates the body of a visitor request with date range values not in a object
export const buildUpdatedBodyParams = ({
  dateRange,
  ...otherBodyParams
}: Inputs) => ({
  ...otherBodyParams,
  ...transformDateRange(dateRange),
});

// Assigns headerFont value based on user selection for FontChangeSelectField in Accessibility Menu
export const getHeaderFont = (selectedValue: string) => {
  switch (selectedValue) {
    case '2':
      return 'OpenDyslexicB';
    case '3':
      return 'Dyslexie';
    case '4':
      return 'Roboto Bold';
    case '5':
      return 'AndikaB';
    default:
      return 'Montserrat';
  }
};

// Assigns bodyFont value based on user selection for FontChangeSelectField in Accessibility Menu
export const getBodyFont = (selectedValue: string) => {
  switch (selectedValue) {
    case '2':
      return 'OpenDyslexic';
    case '3':
      return 'Dyslexie';
    case '4':
      return 'Roboto';
    case '5':
      return 'Andika';
    default:
      return 'OpenSans';
  }
};

// Assigns headerFontSize value based on user selection for FontSizeSelectField in Accessibility Menu
export const getHeaderFontSize = (selectedValue: string) => {
  switch (selectedValue) {
    case '2':
      return '35px';
    case '3':
      return '40px';
    default:
      return '30px';
  }
};

// Assigns bodyFontSize value based on user selection for FontSizeSelectField in Accessibility Menu
export const getBodyFontSize = (selectedValue: string) => {
  switch (selectedValue) {
    case '2':
      return '20px';
    case '3':
      return '25px';
    default:
      return '15px';
  }
};

// Assigns colour two value based on user selection for HighContrastSelectField in Accessibility Menu
export const getColourTwo = (selectedValue: string) => {
  switch (selectedValue) {
    case '2':
      return '#C8C8C8';
    case '3':
      return '#fff569';
    case '4':
      return '#58DC73';
    default:
      return '#E7AC13';
  }
};

// Assigns colour one value based on user selection for HighContrastSelectField in Accessibility Menu
export const getColourOne = (selectedValue: string) => {
  switch (selectedValue) {
    case '2':
      return '#000000';
    case '3':
      return '#602e70';
    case '4':
      return '#21306b';
    default:
      return '#00749b';
  }
};

//this will format date to string in that
export const formatDateToString = (date: Date | null) => {
  if (date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based months
    const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day

    return `${year}-${month}-${day}`;
  } else {
    return '';
  }
};

//this function is used to disable any future dates pas the current date for an Antd rangePicker
export const isFutureDateDisabled = (currentDate: Date) => {
  const today = new Date();
  return currentDate > today; // Disable dates after today
};
