import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import styles from 'components/Fields/header.module.css';
import './TextField.css';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';
import {
  Inputs,
  validationRules,
} from 'components/Fields/componentTypes';

interface Props {
  name: keyof Inputs;
  fieldQuestion: string;
  placeHolder?: string;
  rules?: validationRules;
}

const TextField: FC<Props> = ({
  name,
  fieldQuestion,
  placeHolder,
  rules,
}) => {
  /*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */
  const { control, formState } = useFormContext<Inputs>();

  /*
    This will import to see if there are any errors in the form state
    from which an error message is formatted which will be displayed if so.
  */
  const { errors } = formState;
  const textError = errors[name]?.message?.toString() ?? '';

  //Getting textArea from Antd
  const { TextArea } = Input;

  return (
    <>
      {/* Renders the Question Title and Question */}
      {fieldQuestion && (
        <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      )}
      {placeHolder && <p>{placeHolder}</p>}
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextArea data-testid={`${name}-input`} {...field} />
        )}
      />
      {/* Displays error message if validation rules passed in are invoked */}
      {errors[name] && <ErrorMessage errorMessage={textError} />}
    </>
  );
};

export default TextField;
