import { activityReturn } from 'http/ApiCalls/apiTypes';

//Get endpoint to fetch all activities currently in our DB
export const getAllActivities = async (): Promise<
  activityReturn[]
> => {
  /*
  This file is making a GET endpoint call to /activity/getAllActivities
  with the root URL defined in .env file.
  */
  const response = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/getAllActivities`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    },
  );
  //this will return the response to be handled in the hook.
  return response.json();
};
