import React from 'react';
import { Select } from 'antd';
import { fontSizeOptions } from 'constants/formOptions';
import { getBodyFontSize, getHeaderFontSize } from 'utils/formUtils';

//selectedFontSize is passed in to be used as currently selected value
interface FontSizeSelectFieldProps {
  selectedFontSize: string;
  onChange: (selectedFont: string) => void;
}

const FontSizeSelectField: React.FC<FontSizeSelectFieldProps> = ({
  selectedFontSize,
  onChange,
}) => {
  // Updates --selected-font-size and --selected-header-font-size with user selected values
  const handleChange = (selectedValue: string) => {
    const headerFontSize = getHeaderFontSize(selectedValue);
    const bodyFontSize = getBodyFontSize(selectedValue);

    // Sets --selected-font-size globally
    document.documentElement.style.setProperty(
      '--selected-font-size',
      bodyFontSize,
    );

    // Sets --selected-header-font-size globally
    document.documentElement.style.setProperty(
      '--selected-header-font-size',
      headerFontSize,
    );

    // Updates the placeholder in the dropdown
    onChange(selectedValue);
  };
  /*
 This will render this component to manage form styling
 it will set the font size to the current selected option.
 */
  return (
    <Select
      defaultValue={selectedFontSize}
      style={{
        left: '2%',
        width: '96%',
        height: '60px',
        paddingBottom: '15px',
        textAlign: 'center',
      }}
      onChange={handleChange}
      options={fontSizeOptions}
      data-testid="fontSize"
    />
  );
};

export default FontSizeSelectField;
