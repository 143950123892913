import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Result, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFeedbackForm } from 'hooks/useFeedbackForm';
import useFetchData from 'hooks/useFetchData';
import styles from 'pages/form.module.css';
import ShareLogo from 'pages/FormPageOne/ShareLogo.png';
import FormPageOne from 'pages/FormPageOne/FormPageOne';
import FormPageTwo from 'pages/FormPageTwo/FormPageTwo';
import FormPageThree from 'pages/FormPageThree/FormPageThree';

/*
This file handles rendering the various form pages and the navigation between them.
It also handles navigating to error and success pages on failure of API call.
*/

const FeedbackForm = () => {
  /*
  This is getting the reset method to reset form state
  when submitting another feedback response*/
  const { reset } = useFormContext();

  /*
  This is getting all the functions to
  handle the current page displayed and navigation between them
  as well as the submission functionality of the form.
  */
  const {
    currentPage,
    prevPage,
    nextPage,
    firstPage,
    handleFormSubmit,
    isLoading,
    isSuccess,
  } = useFeedbackForm();

  /*
  This will return all the options that will be loaded into the
  form from an API call. As well as error handling, loading and re-fetching
  functionality.
 */
  const {
    activitiesOptions,
    motivationOptions,
    discoveriesOptions,
    isLoadingData,
    isError,
    handleReloadData,
  } = useFetchData();

  /*
  This will attempt to retry fetching form options if they failed
  and navigated to the error page. It will also navigate to the first
  page to attempt to reload.
  */
  const handleRetry = () => {
    handleReloadData();
    firstPage();
  };

  /*
  this will reset the form from the success page and
  navigate back to the first page
  */
  const handleReset = () => {
    setIsSuccessVisible(false);
    reset();
    firstPage();
  };

  const formPages = [
    {
      key: 0,
      children: (
        //antd Result error page with a button for another attempted retry on failure
        <Result
          status="error"
          title="Oops! Something went wrong"
          subTitle="Failed to fetch data. Please try again later."
          style={{ marginTop: '8%' }}
          extra={
            <Button type="primary" onClick={handleRetry}>
              Retry
            </Button>
          }
        />
      ),
    },
    {
      //form page one with next page navigation and form options passed in to be loaded into a field
      key: 1,
      children: (
        <FormPageOne
          nextPage={nextPage}
          motivationOptions={motivationOptions || []}
          discoveryOptions={discoveriesOptions || []}
        />
      ),
    },
    {
      //form page two with next/prev page navigation and form options passed in to be loaded into a field
      key: 2,
      children: (
        <FormPageTwo
          nextPage={nextPage}
          prevPage={prevPage}
          activityOptions={activitiesOptions || []}
        />
      ),
    },
    {
      /*
      form page three with prev page navigation and handling for submitting the form.
      As well as a isLoading to display an indicator when submitting.
      */
      key: 3,
      children: (
        <FormPageThree
          prevPage={prevPage}
          formSubmit={handleFormSubmit}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: 4,
      children: (
        //antd Result success page with a button to restart the form and return to FormPageOne
        <Result
          status="success"
          title="Thank you for your feedback!"
          subTitle="Your feedback has been successfully submitted."
          style={{ marginTop: '8%' }}
          extra={[
            <Button type="primary" key="back" onClick={handleReset}>
              Back
            </Button>,
          ]}
        />
      ),
    },
  ];

  //if isSuccess is true make the success page for submitting the form visible
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      setIsSuccessVisible(true);
    }
  }, [setIsSuccessVisible, isSuccess]);

  return (
    <>
      <img
        src={ShareLogo}
        alt={'Share Village Logo'}
        className={styles.formImage}
      />
      <h1 className={styles.formTitle}>Feedback Form</h1>
      <div className={styles.formBody}>
        {/*if the form options are loading display this loading symbol as an indicator*/}
        <Spin
          spinning={isLoadingData}
          indicator={<LoadingOutlined style={{ fontSize: '50px' }} />}
        >
          {/*if isError is true display the error page*/}
          {isError && formPages[0]?.children}
          {/*if isError is not true and isSuccess is true display the success page*/}
          {!isError && isSuccessVisible && formPages[4]?.children}
          {/*if not isError and not isSuccessVisible display the normal form page*/}
          {!isError &&
            !isSuccessVisible &&
            formPages[currentPage]?.children}
        </Spin>
      </div>
    </>
  );
};

export default FeedbackForm;
