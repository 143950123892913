import { useFormContext } from 'react-hook-form';
import { Button } from 'antd';
import {
  fieldOptions,
  Inputs,
} from 'components/Fields/componentTypes';
import SelectField from 'components/Fields/SelectField/SelectField';
import TextField from 'components/Fields/TextField/TextField';
import ActivitySelect from 'components/Fields/SelectField/ActivitySelectField';
import styles from 'pages/form.module.css';
import {
  requiredRules,
  requiredRulesThreeHundred,
} from 'constants/formRules';

interface FormPageTwoProps {
  nextPage: () => void;
  prevPage: () => void;
  activityOptions: fieldOptions[];
}
const FormPageTwo = ({
  nextPage,
  prevPage,
  activityOptions,
}: FormPageTwoProps) => {
  const { handleSubmit } = useFormContext<Inputs>();

  return (
    <>
      <form className={styles.formContent}>
        {/* DropDown to select what the users favourite activity was */}
        <SelectField
          name="favouriteActivityId"
          fieldQuestion="What was your favourite activity?"
          options={activityOptions}
          rules={requiredRules}
        />

        {/* DropDown to select what the users least favourite activity was*/}
        <SelectField
          name="leastFavouriteActivityId"
          fieldQuestion="What was your least favourite activity?"
          options={activityOptions}
          rules={requiredRules}
        />

        {/* Text box to allow the user to enter a suggested new activity */}
        <TextField
          name="suggestedNewActivity"
          fieldQuestion="Are there any new activities you would like to see in Share Village in the future?"
          rules={requiredRulesThreeHundred}
        />

        {/* DropDown to allow the user to select multiple activities that would like to rate */}
        <ActivitySelect
          name="activityName"
          fieldQuestion="Please kindly rate any activities you have participated in during your visit."
          options={activityOptions}
          rules={requiredRules}
        />
        {/* Button that takes the user to FormPageThree */}
        <Button
          data-testid={'nextPage'}
          className={styles.submitButton}
          onClick={handleSubmit(nextPage)}
          type="primary"
        >
          Next
        </Button>
        {/* Button that takes the user to FormPageOne */}
        <Button
          data-testid={'prevPage'}
          className={styles.previousButton}
          onClick={prevPage}
          type="primary"
        >
          Previous
        </Button>
      </form>
    </>
  );
};

export default FormPageTwo;
