import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxOptionType, Radio } from 'antd';
import { FC } from 'react';
import styles from 'components/Fields/header.module.css';
import './RadioGroupStyles.css';
import {
  Inputs,
  radioValidationRules,
} from 'components/Fields/componentTypes';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';

interface Props {
  name: keyof Inputs | string;
  fieldQuestion: string;
  options: Array<CheckboxOptionType>;
  rules?: radioValidationRules;
}

/*
This is an Antd RadioGroup which allows a user to select
a radio option from a passed in option list
*/

const RadioGroupField: FC<Props> = ({
  name,
  fieldQuestion,
  options,
  rules,
}) => {
  /*
    This will import various methods from React Hook Form to manage
    form state and control validation rules.
  */
  const { control, formState } = useFormContext<any>();

  /*
    This will import to see if there are any errors in the form state
    from which an error message is formatted which will be displayed if so.
  */
  const { errors } = formState;
  const radioGroupError = errors[name]?.message?.toString() ?? '';

  // Getting Antd RadioGroup component
  const { Group } = Radio;
  return (
    <>
      {/* This will be the question title of above this component in the form */}
      <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field }) => (
          <>
            <Group
              {...field}
              data-testid={`${name}-RadioGroup`}
              options={options}
            />
          </>
        )}
      />
      {/* Displays error message if validation rules passed in are invoked */}
      {!!errors[name] && (
        <div>
          <ErrorMessage errorMessage={radioGroupError} />
        </div>
      )}
    </>
  );
};

export default RadioGroupField;
