/*
Form fields options for GroupType
*/
export const groupType = [
  { value: 'Individual', label: 'Individual' },
  { value: 'School', label: 'School' },
  { value: 'Organisation', label: 'Organisation' },
];

/*
Options to be fed into ageRange select field
This is hard coded as this is a value
for a field in our DB
*/

export const ageRanges = [
  { value: 'Under 12 years old', label: 'Under 12 years old' },
  { value: '12-17 years old', label: '12-17 years old' },
  { value: '18-24 years old', label: '18-24 years old' },
  { value: '25-34 years old', label: '25-34 years old' },
  { value: '35-44 years old', label: '35-44 years old' },
  { value: '45-54 years old', label: '45-54 years old' },
  { value: '55-64 years old', label: '55-64 years old' },
  { value: '65-74 years old', label: '65-74 years old' },
];

//Form introduction paragraph at the start of Page One used to display this text.
export const formIntroductionText = `
At Share Village we value your feedback. It is through this feedback that we are able to improve our services for future visitors. 
We would therefore be very grateful if you would take the time to complete this feedback form. Thank you for your business.
`;

//radio styling for component here as it is needed to function
const radioStyle = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '0.9rem',
};

/*
Options list that will be fed into a
radio group for any fields that need a yes/no option.
*/

export const yesNoOptions = [
  { label: 'Yes', value: true, style: radioStyle },
  { label: 'No', value: false, style: radioStyle },
];

/*
Font change option list. This will be used in the accessibility
menu in order to change the font displayed for styling.
*/

export const fontChangeOptions = [
  { value: '1', label: 'OpenSans' },
  {
    value: '2',
    label: 'OpenDyslexic',
    style: { fontFamily: 'OpenDyslexic' },
  },
  {
    value: '3',
    label: 'Dyslexie',
    style: { fontFamily: 'Dyslexie' },
  },
  {
    value: '4',
    label: 'Andika',
    style: { fontFamily: 'Andika' },
  },
];

/*
Font size option list. This will be used in the accessibility
menu in order to change the font size displayed for styling.
*/

export const fontSizeOptions = [
  { value: '1', label: 'Normal' },
  { value: '2', label: 'Large' },
  { value: '3', label: 'Extra Large' },
];

/*
High contrast color option list. This will be used in the accessibility
menu in order to change the colors displayed for styling on the form.
*/

export const highContrastOptions = [
  { value: '1', label: 'Blue and Orange (default)' },
  { value: '2', label: 'Black and Grey' },
  { value: '3', label: 'Purple and Yellow' },
  { value: '4', label: 'Dark Blue and Light Green' },
];
