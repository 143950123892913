import { visitorInputs } from 'components/Fields/componentTypes';
import { visitorID } from 'http/ApiCalls/apiTypes';

export const visitorSubmit = async (
  visitorInputs: visitorInputs,
): Promise<{ status: number; visitorID: visitorID }> => {
  /*
  This file is making a POST endpoint call to /visitor/addVisitor
  with the root URL defined in .env file.
  */
  const response = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/visitor/addVisitor`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(visitorInputs),
    },
  );

  /*
  This will return the status code and visitorID
  As the visitorID needs to be used in other endpoint calls
  */
  const status = response.status;
  const visitorID: visitorID = await response.json();

  return { status, visitorID };
};
