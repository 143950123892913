import { FC } from 'react';
import { Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Inputs,
  validationRules,
} from 'components/Fields/componentTypes';
import styles from 'components/Fields/header.module.css';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';

interface Props {
  name: keyof Inputs;
  fieldQuestion?: string;
  dynamicFieldQuestion?: string;
  defaultValue?: string;
  placeHolder?: string;
  rules?: validationRules;
}

/*
This is an Antd Input which allows a user to type
and enter information
*/

const InputField: FC<Props> = ({
  name,
  fieldQuestion,
  dynamicFieldQuestion,
  defaultValue,
  rules,
  placeHolder,
}) => {
  /*
  This will import various methods from React Hook Form to manage
  form state and control validation rules.
  */
  const { control, formState } = useFormContext<Inputs>();

  /*
  This will import to see if there are any errors in the form state
  from which an error message is formatted which will be displayed if so.
  */
  const { errors } = formState;
  const inputError = errors[name]?.message?.toString() ?? '';

  return (
    <>
      {/* This will be the question title of above this component in the form */}
      {fieldQuestion && (
        <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      )}
      {/* This will be a secondary title displayed if a dynamic question is passed in */}
      {dynamicFieldQuestion && (
        <p className={styles.dynamicQuestion}>
          {dynamicFieldQuestion}
        </p>
      )}
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ field }) => (
          // Setting placeholder text, so that the form is clearer for the user
          <Input
            placeholder={placeHolder}
            data-testid={`${name}-Input`}
            className={styles.inputStyle}
            {...field}
          />
        )}
      />
      {/*Displays error message if validation rules passed in are invoked*/}
      {errors[name] && <ErrorMessage errorMessage={inputError} />}
    </>
  );
};

export default InputField;
