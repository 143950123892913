import { useFormContext } from 'react-hook-form';
import { Inputs } from 'components/Fields/componentTypes';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { yesNoOptions } from 'constants/formOptions';
import RadioGroupField from 'components/Fields/RadioGroupField/RadioGroupField';
import SliderField from 'components/Fields/SliderField/SliderField';
import TextField from 'components/Fields/TextField/TextField';
import styles from 'pages/form.module.css';
import {
  radioRules,
  requiredRulesThreeHundred,
} from 'constants/formRules';

interface FormPageTwoProps {
  formSubmit: () => void;
  prevPage: () => void;
  isLoading: boolean;
}
const FormPageThree = ({
  formSubmit,
  prevPage,
  isLoading,
}: FormPageTwoProps) => {
  const { handleSubmit } = useFormContext<Inputs>();
  return (
    <>
      <form className={styles.formContent}>
        {/* Renders a spin icon when submitting so the user knows the submit process is occurring */}
        <Spin
          spinning={isLoading}
          indicator={<LoadingOutlined style={{ fontSize: '50px' }} />}
        >
          {/* Radio group to select whether the user would book another day with share village */}
          <RadioGroupField
            name="goodValue"
            fieldQuestion="Would you Book another stay to Share Village?"
            options={yesNoOptions}
            rules={radioRules}
          />

          {/* Slider for the user to rate their experience with share village out of ten */}
          <SliderField
            name="overallExperienceScore"
            fieldQuestion="On a scale of one to ten, how was your visit?"
          />

          {/* Text box for the user to enter their favourite aspect of the visit at share village */}
          <TextField
            name="favouriteAspect"
            fieldQuestion="What was your favourite aspect of the visit?"
            rules={requiredRulesThreeHundred}
          />

          {/* Text box for the user to enter any improvements to their visit at share village */}
          <TextField
            name="improvements"
            fieldQuestion="Would you suggest any improvements we could make?"
            rules={requiredRulesThreeHundred}
          />

          {/* Text box for the user to give a quote about their visit at share village */}
          <TextField
            name="statement"
            fieldQuestion="Would you mind giving us a Quote on your time at Share (We may use this on our publicity from time to time.) ?"
            rules={requiredRulesThreeHundred}
          />

          {/* Button that takes the user to FormPageTwo */}
          <Button
            className={styles.previousButton}
            onClick={prevPage}
            type="primary"
          >
            Previous
          </Button>

          {/* Button that submits the form information to the DB */}
          <Button
            data-testid={'submit'}
            className={styles.submitButton}
            onClick={handleSubmit(formSubmit)}
            type="primary"
            disabled={isLoading}
          >
            Submit
          </Button>
        </Spin>
      </form>
    </>
  );
};

export default FormPageThree;
