import { FC } from 'react';
import { Inputs } from 'components/Fields/componentTypes';
import { Input } from 'antd';
import styles from 'components/Fields/header.module.css';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';
import {
  Controller,
  useFormContext,
  RegisterOptions,
} from 'react-hook-form';

interface Props {
  name: keyof Inputs;
  rules?: RegisterOptions;
  fieldQuestion: string;
  placeHolder?: string;
}
const ValidationField: FC<Props> = ({
  name,
  fieldQuestion,
  rules,
  placeHolder,
}) => {
  /*
    This will import to see if there are any errors in the form state
    from which an error message is formatted which will be displayed if so.
  */
  const { control, formState } = useFormContext<Inputs>();

  /*
    This will import to see if there are any errors in the form state
    from which an error message is formatted which will be displayed if so.
  */
  const { errors } = formState;
  const validationError = errors[name]?.message?.toString() ?? '';

  return (
    <>
      {/* Renders the Question Title and Question */}
      <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={placeHolder}
            allowClear
            data-testid={`${name}-Input`}
            className={styles.inputStyle}
          />
        )}
      />
      {/* Displays error message if validation rules passed in are invoked */}
      {errors.email && (
        <ErrorMessage errorMessage={validationError} />
      )}
    </>
  );
};

export default ValidationField;
