import { experienceInputs } from 'components/Fields/componentTypes';
import { visitorID } from 'http/ApiCalls/apiTypes';

export const experienceSubmit = async (
  experienceInputs: experienceInputs,
  visitorId: visitorID,
): Promise<number> => {
  /*
  This file is making a POST endpoint call to /experience/addExperience
  with the root URL defined in .env file.
  */
  const response = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/experience/addExperience`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        ...experienceInputs,
        visitorId: visitorId,
      }),
    },
  );

  //return the status code from the API call
  return response.status;
};
