import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styles from './app.module.css';
import { Inputs } from 'components/Fields/componentTypes';
import FeedbackForm from 'pages/Form/FeedbackForm';
import AccessibilityMenu from 'components/AccessibilityMenu/AccessibilityMenu';
import SvgFooterComponent from 'components/SVGComponents/SvgFooterComponent';
import CompanyHeader from 'components/CompanyHeader/CompanyHeader';

//react query client to pass into application
const queryClient = new QueryClient();

/*
This App file will render the entire application with
all its various components.
*/

function App() {
  const formControls = useForm<Inputs>({ mode: 'onChange' });

  // Controls the open/closed state of the Accessibility Menu
  const [isAccessibilityMenuOpen, setOpen] = useState(false);
  const toggleAccessibilityMenu = () => {
    setOpen(!isAccessibilityMenuOpen);
  };

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        {/* Renders Header with social media links, contact information and link to existing site */}
        <CompanyHeader />
        <div className={styles.app}>
          <FormProvider {...formControls}>
            {/* Renders FeedbackForm */}
            <FeedbackForm />
          </FormProvider>
          {/* Button that controls the open/closed state of the Accessibility Menu */}
          <Button
            className={styles.accessibilityMenuBTN}
            onClick={toggleAccessibilityMenu}
            data-testid="accessibility"
          >
            <MenuOutlined style={{ fontSize: '24px' }} />
            <span className={styles.accessibilityMenuText}>
              Accessibility Settings
            </span>
          </Button>
          <AccessibilityMenu
            isOpen={isAccessibilityMenuOpen}
            closeMenu={() => setOpen(false)}
          />
          <div className={styles.footer}>
            {/* Renders Wave SVG at bottom of page to match with existing Share Discovery Village Site styling */}
            <SvgFooterComponent />
          </div>
        </div>
      </QueryClientProvider>
    </div>
  );
}

export default App;
