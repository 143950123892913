import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './accessibility.module.css';
import { useAccessibility } from 'hooks/useAccessibility';
import FontSizeSelectField from 'components/Fields/SelectField/FontSizeSelectField';
import FontChangeSelectField from 'components/Fields/SelectField/FontChangeSelectField';
import HighContrastSelectField from 'components/Fields/SelectField/HighContrastSelectField';

/*
This component will accept an isOpen and CloseMenu prop
which will control the rendering of this menu
*/
interface AccessibilityMenuProps {
  isOpen: boolean;
  closeMenu: () => void;
}

/*
This Menu will display Accessibility Options
to change FontSize, Font and change to different High Contrast
colors.
*/

const AccessibilityMenu: React.FC<AccessibilityMenuProps> = ({
  isOpen,
  closeMenu,
}) => {
  /*
  hook used to return all global variables selectedFont, selectedFontSize, and selectedHighContrast
  and the methods used in useAccessibility/formUtils which set styling values of the form
  */
  const {
    selectedFont,
    selectedFontSize,
    selectedHighContrast,
    handleFontChange,
    handleFontSizeChange,
    handleHighContrastChange,
  } = useAccessibility();

  return (
    <>
      {isOpen && (
        <>
          {/* Button that controls the open/closed state of the Accessibility Menu*/}
          <div className={styles.overlay} onClick={closeMenu}></div>
          <div className={styles.accessibilityMenu}>
            <div className={styles.accessibilityMenuHeader}>
              Accessibility Menu
              {/* Secondary button to close the menu */}
              <Button
                className={styles.closeBTN}
                icon={<CloseOutlined style={{ fontSize: '24px' }} />}
                onClick={closeMenu}
                data-testid={'close'}
              />
            </div>
            {/* Accessibility option Combo boxes to change the  global font size */}
            <div className={styles.fontSize}>Change Font Size</div>
            <FontSizeSelectField
              selectedFontSize={selectedFontSize}
              onChange={handleFontSizeChange}
            />
            {/* Accessibility option Combo boxes to change the  global font */}
            <div className={styles.fontChange}>Change Font</div>
            <FontChangeSelectField
              selectedFont={selectedFont}
              onChange={handleFontChange}
            />
            {/* Accessibility option Combo boxes to change the  global colour contrast */}
            <div className={styles.highContrast}>
              Enable High Contrast
            </div>
            <HighContrastSelectField
              selectedHighContrast={selectedHighContrast}
              onChange={handleHighContrastChange}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AccessibilityMenu;
