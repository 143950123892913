import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'antd';
import {
  fieldOptions,
  Inputs,
} from 'components/Fields/componentTypes';
import ValidationField from 'components/Fields/ValidationField/ValidationField';
import DatePickerRange from 'components/Fields/DatePickerField/DatePickerRange';
import InputField from 'components/Fields/InputField/InputField';
import RadioGroupField from 'components/Fields/RadioGroupField/RadioGroupField';
import SelectField from 'components/Fields/SelectField/SelectField';
import styles from 'pages/form.module.css';
import 'components/Fields/SelectField/fonts.css';
import {
  groupType,
  yesNoOptions,
  ageRanges,
  formIntroductionText,
} from 'constants/formOptions';
import {
  emailRules,
  requiredRules,
  dateRules,
  radioRules,
  requiredRulesText,
} from 'constants/formRules';

interface FormPageOneProps {
  nextPage: () => void;
  motivationOptions: fieldOptions[];
  discoveryOptions: fieldOptions[];
}
const FormPageOne = ({
  nextPage,
  motivationOptions,
  discoveryOptions,
}: FormPageOneProps) => {
  const { watch, handleSubmit, resetField } =
    useFormContext<Inputs>();

  //watching fields that will display dynamic options with a certain selected value
  const watchGroupType = watch('groupType');
  const watchSimilarActivity = watch('similarExperience');

  //reset dynamic fields when they are not displayed so the value is not kept when hidden
  useEffect(() => {
    if (watchGroupType === 'Individual') {
      resetField('groupName');
    }
    if (!watchSimilarActivity) {
      resetField('whichSimilarExperience');
    }
  }, [watchGroupType, watchSimilarActivity, resetField]);

  return (
    <>
      <form className={styles.formContent}>
        <p className={styles.paragraph}>{formIntroductionText}</p>

        {/* Text box to enter user email address */}
        <ValidationField
          name="email"
          fieldQuestion="Email Address"
          rules={emailRules}
          placeHolder="Enter your email address"
        />

        {/* DropDown to enter user group type */}
        <SelectField
          name="groupType"
          placeHolder="Select a group type"
          fieldQuestion="Are you an individual or here as a member of a school or organisation?"
          options={groupType}
          rules={requiredRules}
        />

        {/* Dynamic field to enter Group Name, if 'School' or 'Organisation' is entered as group type */}
        {(watchGroupType === 'School' ||
          watchGroupType === 'Organisation') && (
          <InputField
            name="groupName"
            dynamicFieldQuestion="What is the name of your School/Organisation?"
            rules={requiredRulesText}
          />
        )}

        {/* Ant d Date picker to enter start and end date of trip */}
        <DatePickerRange
          name="dateRange"
          fieldQuestion="What was the Date of your Visit?"
          rules={dateRules}
        />

        {/* Text box to enter where the users are from */}
        <InputField
          name="location"
          fieldQuestion="What location is your group from?"
          rules={requiredRulesText}
          placeHolder="Enter your group's location"
        />

        {/* Radio Group to enter whether this was the users first visit to Share Discovery Village*/}
        <RadioGroupField
          name="firstVisit"
          fieldQuestion="Is this your first visit to Share Village?"
          options={yesNoOptions}
          rules={radioRules}
        />

        {/* DropDown to select which age range the user falls into */}
        <SelectField
          name="ageRange"
          fieldQuestion="What was the age range of your group?"
          placeHolder="Select an age range"
          options={ageRanges}
          rules={requiredRules}
        />

        {/* DropDown to select how user heard about Share Village*/}
        <SelectField
          name="discoveryId"
          fieldQuestion="How did you hear about Share Discovery Village?"
          placeHolder="Select a way"
          options={discoveryOptions}
          rules={requiredRules}
        />

        {/* DropDown to select why user chose to go to Share Village*/}
        <SelectField
          name="motivationId"
          fieldQuestion="What made you choose to book your day with Share village?"
          placeHolder="Select why"
          options={motivationOptions}
          rules={requiredRules}
        />

        {/* Radio Group to select whether the user had been to a site like Share Village before */}
        <RadioGroupField
          name="similarExperience"
          fieldQuestion="Have you ever been to an activity centre like Share Village before?"
          options={yesNoOptions}
          rules={radioRules}
        />
        {/* Dynamic field to enter which activity centre they have been to before,
            only renders if 'yes' is selected in 'similarExperience' RadioGroup above */}
        {watchSimilarActivity === true && (
          <InputField
            name="whichSimilarExperience"
            dynamicFieldQuestion="What is the name of the other Activity Centre?"
            rules={requiredRulesText}
          />
        )}
        <div>
          {/* Button that navigates to FormPageTwo */}
          <Button
            data-testid={'nextPage'}
            className={`${styles.submitButton} ${styles.pageOneNextButton}`}
            onClick={handleSubmit(nextPage)}
            type="primary"
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormPageOne;
