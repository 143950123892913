import { useState } from 'react';

/*
 * This hook allows the FontChangeSelectField, FontSizeSelectField and the HighContrastSelectField to
 * update their default value, so that when the Accessibility Menu is closed and reopened, they continue to
 * show the option for the currently selectedFont, selectedFontSize and the selectedHighContrast
 * */

export const useAccessibility = () => {
  const [selectedFont, setSelectedFont] = useState<string>('1');
  const [selectedFontSize, setSelectedFontSize] =
    useState<string>('1');
  const [selectedHighContrast, setSelectedHighContrast] =
    useState<string>('1');

  const handleFontChange = (selectedFont: string) => {
    setSelectedFont(selectedFont);
  };

  const handleFontSizeChange = (selectedFontSize: string) => {
    setSelectedFontSize(selectedFontSize);
  };

  const handleHighContrastChange = (selectedHighContrast: string) => {
    setSelectedHighContrast(selectedHighContrast);
  };

  return {
    selectedFont,
    selectedFontSize,
    selectedHighContrast,
    handleFontChange,
    handleFontSizeChange,
    handleHighContrastChange,
  };
};
