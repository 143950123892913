import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSubmitRequest } from './useSubmitRequest';
import { extractPageData } from 'utils/formUtils';
import {
  visitorDataKeys,
  experienceDataKeys,
  activityRatingDataKey,
} from 'constants/formKeys';
import {
  activityRatingInputs,
  endpointData,
} from 'components/Fields/componentTypes';

export const useFeedbackForm = () => {
  /*
  CurrentPage is used to manage what page of the form is currently displayed
  these various methods are then used to navigate to specific pages when needed
  */
  const [currentPage, setCurrentPage] = useState(1);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);
  const firstPage = () => setCurrentPage(1);

  //use of get values to get specific form fields to go to a certain endpoint
  const { getValues } = useFormContext();

  //getting the submission functionality for the form with isLoading and isSuccess states as well
  const { onSubmit, isLoading, isSuccess } = useSubmitRequest();

  const handleFormSubmit = () => {
    const formState = getValues();

    //getting specific values for visitorFields to send to endpoint
    const visitorData: endpointData = extractPageData(
      visitorDataKeys,
      formState,
    );

    //getting specific values for experience fields to send to endpoint
    const experienceData: endpointData = extractPageData(
      experienceDataKeys,
      formState,
    );

    //getting the activityNames that are currently selected
    const selectedActivities: string[][] = getValues(
      activityRatingDataKey,
    );

    // Format the selectedActivities and their ratings
    const activityRatingData: activityRatingInputs[] = [];

    /*
      For every activityName selected get the number rating slider value for this.
      */
    selectedActivities.forEach((activityNames: string[]) => {
      activityNames.forEach((activityName: string) => {
        const ratingKey = `${activityName}-rating`; // if activity is selected get its score
        const score = formState[ratingKey];
        activityRatingData.push({ activityName, score });
      });
    });

    //call onSubmit functionality with data seperated to be send to each API endpoint
    onSubmit({
      visitorData: visitorData,
      experienceData: experienceData,
      activityRatingData: activityRatingData,
    });
  };

  //returning all functions and states to be used in form
  return {
    setCurrentPage,
    currentPage,
    nextPage,
    prevPage,
    firstPage,
    handleFormSubmit,
    isLoading,
    isSuccess,
  };
};
