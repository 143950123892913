/*
This is for an email validation field.
This performs a presence check to prevent empty fields.
It also prevents an invalid email addresses and limits them
to 50 characters
*/

export const emailRules = {
  required: 'Please input this field!',
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'Please enter a valid email address!',
  },
  maxLength: {
    value: 50,
    message:
      'Maximum length exceeded for Email (50 characters maximum)!',
  },
};

/*
This is for a RangePicker calendar field for picking a start and end date.
This performs a presence check to prevent empty fields.
It also prevents an invalid date being entered
*/

export const dateRules = {
  required: 'Please input this field!',
  pattern: {
    value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    message: 'Please enter a valid date!',
  },
};

/*
This is for a radio group field.
This will check if the value is false/true
and if not display an error message
*/

export const radioRules = {
  validate: (value: boolean) =>
    value === true || value === false || 'Please input this field!',
};

/*
This is simple validation for
a presence check for select fields used in the form
*/

export const requiredRules = {
  required: 'Please input this field!',
};

/*
This is simple validation for
a presence check for any text fields in the form.
This will prevent anything more than 50 characters being
entered.
*/

export const requiredRulesText = {
  required: 'Please input this field!',
  maxLength: {
    value: 50,
    message: 'Maximum length exceeded (50 characters maximum)!',
  },
};

/*
This is simple validation for
a presence check for any text fields in the form.
This will prevent anything more than 100 characters being
entered.
*/

export const requiredRulesLonger = {
  required: 'Please input this field!',
  maxLength: {
    value: 100,
    message: 'Maximum length exceeded (100 characters maximum)!',
  },
};

/*
This is simple validation for
a presence check for any text fields in the form.
This will prevent anything more than 300 characters being
entered.
*/

export const requiredRulesThreeHundred = {
  required: 'Please input this field!',
  maxLength: {
    value: 300,
    message: 'Maximum length exceeded (300 characters maximum)!',
  },
};
