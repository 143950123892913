import { notification } from 'antd';
import { SubmitHandler } from 'react-hook-form';
import { buildUpdatedBodyParams } from 'utils/formUtils';
import {
  activityRatingInputs,
  Inputs,
} from 'components/Fields/componentTypes';
import { visitorID } from 'http/ApiCalls/apiTypes';
import { useExperienceMutation } from './useExperienceMutation';
import { useActivityRatingMutation } from './useActivityRatingMutation';
import { useVisitorMutation } from './useVisitorMutation';

//types for different endpoint data
type FormData = {
  visitorData: Inputs;
  experienceData: Inputs;
  activityRatingData: activityRatingInputs[];
};

export const useSubmitRequest = () => {
  //getting experience endpoint call mutate function and isLoading/success state
  const {
    mutateExperience,
    isSuccessExperience,
    isLoadingExperience,
  } = useExperienceMutation();

  //getting activity rating endpoint call mutate function and isLoading/success state
  const {
    mutateActivityRating,
    isSuccessActivityRating,
    isLoadingActivityRating,
  } = useActivityRatingMutation();

  //getting visitor endpoint call mutate function and isLoading/success state
  const {
    mutate: mutateVisitor,
    isLoading,
    error,
    isSuccess,
  } = useVisitorMutation();

  //this is the combined loading state for all calls that will be made on form submission
  const combinedLoading =
    isLoading || isLoadingExperience || isLoadingActivityRating;

  //this is the combined isSuccess state for all calls made on form submission to change to success page
  const combinedSuccess =
    isSuccess && isSuccessExperience && isSuccessActivityRating;

  const onSubmit: SubmitHandler<any> = async (formData: FormData) => {
    //visitor endpoint is called with its data
    mutateVisitor(buildUpdatedBodyParams(formData.visitorData), {
      onSuccess: (visitorID: visitorID) => {
        /*
        If the visitor endpoint is successful and returns the visitorID
        calls are then made to the experience and activity rating endpoints.
        This needs the returned visitorID to function.
        Each endpoint is called with its related information
        */
        mutateExperience({
          experienceData: formData.experienceData,
          visitorId: visitorID,
        });
        mutateActivityRating({
          activityRatingData: formData.activityRatingData,
          visitorId: visitorID,
        });
      },
      onError: (error) => {
        /*
        On failure of the visitor endpoint an error notification is displayed showing
        what has gone wrong. No further calls are made as a visitor id is needed for experience/activity
        rating to achieve success.
        */
        const errorMessage =
          (error as { message?: string }).message ||
          'There was an error submitting your feedback.';

        notification.error({
          message: 'Form Submission Error',
          description: errorMessage,
        });
      },
    });
  };

  //return of submit functionality to be used directly by the form
  return {
    onSubmit,
    isLoading: combinedLoading,
    error,
    isSuccess: combinedSuccess,
  };
};
