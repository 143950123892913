import { apiReturnType } from 'http/ApiCalls/apiTypes';

//Get endpoint to fetch all discovery options currently in our DB
export const getAllDiscoveries = async (): Promise<
  apiReturnType[]
> => {
  /*
  This file is making a GET endpoint call to /discoveries/getAllDiscoveries
  with the root URL defined in .env file.
  */
  const response = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/discoveries/getAllDiscoveries`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    },
  );
  //this will return the response to be handled in the hook.
  return response.json();
};
