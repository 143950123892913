import { activityRatingInputs } from 'components/Fields/componentTypes';
import { visitorID } from 'http/ApiCalls/apiTypes';

export const activityRatingSubmit = async (
  activityRatingInputs: activityRatingInputs[],
  visitorId: visitorID,
): Promise<number> => {
  /*
  This file is making a GET endpoint call to /activity/addActivityRating
  with the root URL defined in .env file.
  */
  const response = await fetch(
    `${process.env.REACT_APP_API_PREFIX}/activity/addActivityRating`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(
        activityRatingInputs.map(
          (activityData: activityRatingInputs) => ({
            ...activityData,
            visitorId,
          }),
        ),
      ),
    },
  );

  //return the status code
  return response.status;
};
