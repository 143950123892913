import { useMutation } from 'react-query';
import { visitorSubmit } from 'http/ApiCalls';
import { visitorInputs } from 'components/Fields/componentTypes';

/*
This hook is for returning functionality around submitting to the
Add Visitor endpoint at /visitor/addVisitor.

This will then allow the methods from useMutation to be expanded and returned
with what is needed
*/

export const useVisitorMutation = () => {
  return useMutation(
    async (visitorInputs: visitorInputs) => {
      const { status, visitorID } =
        await visitorSubmit(visitorInputs);

      /*if status code is successfully at 200
      return visitorID and if not throw an error*/
      if (status === 200) {
        return visitorID;
      } else {
        throw new Error('Failed to Submit Form ');
      }
    },
    {
      retry: 3, //retry 3 times after the first call if submit failed
    },
  );
};
