import { Controller, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import './DatePickerStyles.css';
import styles from 'components/Fields/header.module.css';
import {
  Inputs,
  date,
  validationRules,
} from 'components/Fields/componentTypes';
import {
  formatDateToString,
  isFutureDateDisabled,
} from 'utils/formUtils';
import ErrorMessage from 'components/Fields/ErrorField/ErrorMessage';

interface Props {
  name: keyof Inputs;
  fieldQuestion: string;
  rules: validationRules;
}

/*
This is an Antd RangePicker which allows a calendar to be used
to select a start and end date.
*/

const DatePickerRange: FC<Props> = ({
  name,
  fieldQuestion,
  rules,
}) => {
  /*
  This will import various methods from React Hook Form to manage
  form state and control validation rules.
  */
  const { control, formState, getValues } = useFormContext<Inputs>();

  /*
  This will import to see if there are any errors in the form state
  from which an error message is formatted which will be displayed if so. 
  */
  const { errors } = formState;
  const dateRangeError = errors[name]?.message?.toString() ?? '';

  //Getting rangePicker component from Antd Datepicker
  const { RangePicker } = DatePicker;

  /*
  Get default value for date range so that if it previously
  held a value it still displays this when this component is rendered
  */
  const defaultValue = getValues('dateRange');

  return (
    <>
      {/* This will be the question title of above this component in the form */}
      <h3 className={styles.questionTitle}>{fieldQuestion}</h3>
      {/*
      This will render this component in a Controller to manage form state
      it will set the name, validation rules and setting the control object
      form the useFormContext into this controller.
      */}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field }) => (
          <RangePicker
            data-testid={`${name}-RangePicker`}
            className={`${styles.rangeFinder} ${styles.customDatePickerStyles}`}
            format="DD/MM/YYYY"
            //input is read only here can only select date from calendar picker
            inputReadOnly
            //if the form state has a current value for Date range set this component to this.
            defaultValue={
              defaultValue
                ? [
                    dayjs(defaultValue.startDate).startOf('day'),
                    dayjs(defaultValue.endDate).endOf('day'),
                  ]
                : undefined // Set defaultValue as undefined if it's not defined
            }
            //onChange of this method make an object with separate values for start and end date
            onChange={(dates: any) => {
              const formattedDates: date = {
                startDate:
                  dates && dates[0]
                    ? formatDateToString(dates[0].toDate())
                    : '',
                endDate:
                  dates && dates[1]
                    ? formatDateToString(dates[1].toDate())
                    : '',
              };
              field.onChange(formattedDates);
            }}
            disabledDate={(current) =>
              //future dates past the current day are disabled
              isFutureDateDisabled(current?.toDate() || new Date())
            }
          />
        )}
      />
      {/*Displays error message if validation rules passed in are invoked*/}
      {errors.dateRange && (
        <ErrorMessage errorMessage={dateRangeError} />
      )}
    </>
  );
};

export default DatePickerRange;
