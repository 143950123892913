import { useMutation } from 'react-query';
import { notification } from 'antd';
import { activityRatingInputs } from '../components/Fields/componentTypes';
import { visitorID } from 'http/ApiCalls/apiTypes';
import { activityRatingSubmit } from 'http/ApiCalls';

/*
This hook is for returning functionality around submitting to the
Activity Rating endpoint at /activity/addActivityRating.

It will return a mutate function for triggering a submit and HTTP call.
It will also return an isLoading for rendering a loading effect and will
also return an isSuccess to determine whether to route to an error page.
*/

export const useActivityRatingMutation = () => {
  /*
  This is getting the various functions from useMutation
  Then giving them a unique name so that
  they can be returned and not conflict with other mutations.
   */
  const {
    mutate: mutateActivityRating,
    isSuccess: isSuccessActivityRating,
    isLoading: isLoadingActivityRating,
  } = useMutation(
    async ({
      activityRatingData,
      visitorId,
    }: {
      /*this endpoint requires an array of activity ratings and names and a visitor id*/
      activityRatingData: activityRatingInputs[];
      visitorId: visitorID;
    }) => {
      //making the call to this endpoint with the body needed
      const activityRatingStatus = await activityRatingSubmit(
        activityRatingData,
        visitorId,
      );
      //if this is not 201 and this call did not succeed this will trigger an error
      if (activityRatingStatus !== 201) {
        throw new Error('Activity Rating Submit Failed');
      }
    },
    {
      retry: 3, //after the first failed attempt this call will be retried 3 times
      onError: (error) => {
        /*
        On a network error an Antd notification is used to display what has gone wrong
        for this specific endpoint
        */
        const errorMessage =
          (error as { message?: string }).message ||
          'There was an error submitting your feedback.';

        notification.error({
          message: 'Form Submission Error',
          description: errorMessage,
        });
      },
    },
  );

  //the different useMutation methods returned in the hook so that they can be accessed and used.
  return {
    isLoadingActivityRating,
    isSuccessActivityRating,
    mutateActivityRating,
  };
};
